import React, { useRef } from "react";
import "./../home.css";

const FocusPoints = () => {
  const focusPoints = [
    {
      title: "Vårt personliga engagemang",
      description:
        "Vi är engagerade och glada över att hjälpa våra kunder med trädgårdens projekt. Genom flexibilitet och lyhördhet ska det vara trevligt, enkelt och tryggt att samarbeta med oss!",
      align: "right fp-tall",
      heightMatch: true,
    },
    {
      title: "Vår kompetens",
      description:
        "Vi håller oss uppdaterade genom kontinuerlig vidareutbildning inom ämnen som är relevanta för våra olika tjänster. Vi samarbetar med kunniga lokala odlare och kan erbjuda växter och jord av hög kvalitet, anpassat till vårt klimat!",
      align: "left fp-tall",
      heightMatch: true,
    },
    {
      title: "Vi är med från start till mål!",
      description:
        "Vi erbjuder ett brett utbud av trädgårdstjänster, och är med er hela processen från idé till den färdiga trädgården.",
      align: "right span2",
      heightMatch: false,
    },
  ];

  return (
    <div className="info_focusPoints">
      <div className="info_focusPoints_grid grid">
        {focusPoints.map((service, index) => {
          return (
            <div key={index} className={"box " + service.align}>
              <div className="info_box">
                <h3 className="box_title">{service.title}</h3>
                <div className="box_line"></div>
                <p className="box_description">{service.description}</p>
              </div>

              <div className="box_line"></div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FocusPoints;
