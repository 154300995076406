import React from "react";
import AboutSection from "../components/about/AboutSection";
import PolymorphicHero from "../components/hero/PolymorphicHero";
import { Helmet } from "react-helmet";

const About = () => {
  return (
    <>
      <Helmet>
        <title>Lovisas Trädgårdstjänst - Om oss</title>
        <meta
          name="description"
          content="Lovisas trädgårdstjänst drivs av mig, Lovisa, med ett stort
          intresse för trädgård och blommor i alla dess former."
        />
      </Helmet>
      <PolymorphicHero title={"Om oss"} subtitle={"Läs mer"} />
      <AboutSection />
    </>
  );
};

export default About;
