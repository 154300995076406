import React, { useState, useEffect } from "react";
import "./about.css";
import { listAll, ref, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase.js";

const AboutSection = () => {
  const [leftImg, setLeftImg] = useState([]);
  const [rightImg, setRightImg] = useState([]);
  const leftImgRef = ref(storage, "om/vanster");
  const rightImgRef = ref(storage, "om/hoger");

  useEffect(() => {
    listAll(leftImgRef).then((response) => {
      response.items.forEach((item) => {
        getDownloadURL(item).then((url) => {
          setLeftImg((prev) => [...prev, url]);
        });
      });
    });

    listAll(rightImgRef).then((response) => {
      response.items.forEach((item) => {
        getDownloadURL(item).then((url) => {
          setRightImg((prev) => [...prev, url]);
        });
      });
    });
  }, []);
  return (
    <section className="about section">
      <div className="about_div">
        <div className="about_container container grid">
          <div className="about_description">
            <p className="about_text title">
              Lovisas trädgårdstjänst drivs av mig, Lovisa, med ett stort
              intresse för trädgård och blommor i alla dess former!
            </p>
            <p className="about_text">
              Mitt intresse för växter kom tidigt, med en mormor som ofta tog
              med mig ut i trädgården. Efter att jag under min uppväxt beundrat
              hennes grusgång kantad av pärlhyacinter om våren, kom jag att bli
              intresserad av skapande i färg och form, växter, liksom inredning
              och trädgårdsdesign.
            </p>
            <p className="about_text">
              Jag har sedan dess utbildat mig till trädgårdsmästare,
              trädgårdsdesigner och florist. I mitt arbete jobbar jag med att
              planera, utveckla och sköta om växter i alla dess former. Vi hörs!
            </p>
            <p className="about_text">/ Lovisa</p>
          </div>
          <div className="about_imgs grid">
            <img src={leftImg[0]} className="about_img"></img>
            <img src={rightImg[0]} className="about_img"></img>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
