import React, { useState } from "react";
import "./services.css";

const ServiceBox = ({
  title,
  time,
  description,
  closed_desc,
  expandable,
  image,
}) => {
  const [open, setOpen] = useState(!expandable);

  if (!open) {
    description = closed_desc;
  }

  return (
    <div className="service_wrapper">
      <div className={open ? "service_box open" : "service_box closed"}>
        <h3 className="service_title">{title}</h3>
        <div className="service_line"></div>
        <div className="service_img_div">
          <div className={"service_img " + image}></div>
        </div>
        <div className="service_description">
          {description.map((part, index) => {
            return (
              <p key={index} className="service_description_text">
                {part}
              </p>
            );
          })}
        </div>
      </div>
      <div className="service_line"></div>
      <div className={expandable ? "button_div" : "no_button"}>
        <button
          onClick={() => {
            setOpen(!open);
          }}
          className="show_more"
        >
          {open ? "Visa mindre" : "Visa mer"}
          <i className={open ? "uil uil-arrow-up" : "uil uil-arrow-down"}></i>
          <div className="button_line"></div>
        </button>
      </div>
    </div>
  );
};

export default ServiceBox;
