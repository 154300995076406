import React from "react";
import PolymorphicHero from "../components/hero/PolymorphicHero";
import ImageGallery from "../components/gallery/ImageGallery";
import { Helmet } from "react-helmet";

const Gallery = () => {
  return (
    <>
      <Helmet>
        <title>Lovisas Trädgårdstjänst - Galleri</title>
        <meta
          name="description"
          content="Se arbete som vi på Lovisas trädgårdstjänst har utfört här i vårt galleri."
        />
      </Helmet>
      <PolymorphicHero
        title={"Kika i galleriet"}
        subtitle={"Ny inspiration?"}
      />
      <ImageGallery />
    </>
  );
};

export default Gallery;
