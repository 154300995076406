import React, { useEffect, useRef } from "react";
import "./../home.css";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const Flower = () => {
  const potRef = useRef();
  const flowerRef = useRef();

  useEffect(() => {
    gsap.fromTo(
      flowerRef.current,
      {
        strokeDashoffset: 12750,
      },
      {
        strokeDashoffset: 0,
        duration: 2,
        ease: "power1.easeOut",
        scrollTrigger: {
          trigger: potRef.current,
          start: "center bottom",
        },
      }
    );
  }, []);

  return (
    <div className="flower_div">
      <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 1787.5 1974.6"
        fill="none"
        stroke="#ffffff"
        strokeWidth="8pt"
        stroke-strokelinecap="round"
        strokeLinejoin="round"
        strokeMiterlimit="10"
        className="flower"
      >
        <path
          ref={flowerRef}
          className="st2 svg-hidden"
          d="M928.1,1963.4c4.6-48.3,10.1-174.6,5.5-302.9l0,0c0,0,0,0,0,0c-2-56.6,0.2-55-7.1-123.7c0,0-0.9-9.9-0.9-9.9
l165.4-169.1c81.4,51.9,202.6,27.8,314-105c142.4-169.7,366.7-157.6,366.7-157.6c-233.3-169.7-406.1-203-630.3-111.7
c-219.9,89.6-112.4,305.3-108.2,313.6c-46.3,59-98.1,117.7-115.5,153c0,0,0,0,0,0c-24.8-216.5-70.3-499.4-190.4-789c0,0,0,0,0,0
c11-3,11.8-4.2,21.7-9.5l0,0c0,0-34.5,58.2,75.1,175.2c112.9,120.5,203.5,77.6,218.6,61.3c15.1-16.3,82.6-93.9-37-211.9
s-193.3-88.8-193.3-88.8l0,0c9.9-19.1,15.8-40.6,16.6-63.4l0,0c0,0,27.8,57.1,185.3,27.4c162.3-30.6,174.7-130.1,169.1-151.5
s-34.9-120.1-198.7-82.5C820.8,354.9,805,436.2,805,436.2l0,0c-12.3-19.1-28.8-35.3-48.3-47.2l0,0c0,0,66.2,5,116.6-147.2
C925.2,85,844.2,26,822.6,20.5s-122-27.7-168.4,133.9S669,366.8,669,366.8l0.8,0.2c-21.8,1.1-42.4,6.9-60.8,16.3
c0-0.1,34-57.5-75.8-174.1c-113.2-120.2-203.7-77-218.8-60.7c-15,16.3-82.4,94.1,37.6,211.8s192.2,86.6,192.2,86.6l0,0
c-10.4,19.5-16.7,41.6-17.6,65l-0.1-0.5c0,0-33.8-59.4-190.2-24.2c-161.1,36.3-170,136.2-163.6,157.4
c6.4,21.2,39.1,118.8,201.5,75.4c149-39.8,173.5-108.8,176.5-119.9c13.6,21.1,32.4,38.5,54.5,50.6c0,0-91.9-4.6-131.2,155.9
c-39.3,160.4,44.9,212.6,66.6,217.4c21.7,4.8,122.7,24.1,164.4-138.8c41.7-162.8-27.1-215.9-27.1-215.9c11.8,0,17,0,28-2.6
c0,0,0,0,0,0l149.9,884.9c-24.8-29.9-55.1-62.9-90.6-95c0.2-0.3,0.3-0.6,0.5-0.9c0,0,69.6-275.7-257.6-287.8
c-327.3-12.1-490.9,225.8-490.9,225.8s157.8-54,377.4,85.9c167.7,106.8,244.5,124.9,310.6,60l162.6,86.1l50.4,338.1"
        />
      </svg>
      <div ref={potRef} className="flower_pot"></div>
      <div className="overlay"></div>
    </div>
  );
};

export default Flower;
