import React from "react";
import "./faq.css";

const Faqs = () => {
  const questions = [
    {
      question: "Vilka tjänster utför ni?",
      answer: [
        "Vi utför tjänster inom trädgårdsskötsel, samt trädgårdsdesign och anläggning av rabatter, häckar och andra gröna ytor.",
      ],
    },
    {
      question: "Vilka trädgårdsarbeten utför ni?",
      answer: [
        "Vi arbetar med att beskära häckar, rensa ogräs, beskära träd, klippa gräs med mera -  information om alla tjänster vi tillhandahåller kan ses under fliken 'Tjänster'.",
      ],
    },
    {
      question: "Hur är era öppettider?",
      answer: [
        "Vi arbetar ute hos kund under dagtid samt kvällstid vid behov.",
        "Vi arbetar vanligtvis mellan 7-18 måndag-lördag.",
      ],
    },
    {
      question: "Vart arbetar ni?",
      answer: [
        "Vi utgår från Alingsås och arbetar främst i Alingsås, Lerum, Göteborg, Vårgårda och Herrljunga, men tar oss an projekt i hela Västra götaland.",
      ],
    },
    {
      question: "Hur fungerar en trädgårdskonsultation?",
      answer: [
        "Vi ses i er trädgård och går gemensamt runt och planerar era ytor.",
        "Vi gör upp en plan för att ni ska få en lättskött och välplanerad trädgård!",
      ],
    },
    {
      question: "Kan ni designa och planera min trädgård?",
      answer: [
        "Vi planerar hela eller delar av trädgården. Med våra tjänster inom trädgårdsdesign planerar vi rabatter och planteringar. Vi gör collage, växtlistor och planteringsplaner samt utför hela projektet, med design, planering, inköp och anläggning.",
      ],
    },
    {
      question: "Beskär ni alla typer av fruktträd?",
      answer: [
        "Vi beskär fruktträd och prydnadsträd kontunuerligt under året!",
      ],
    },
    {
      question: "Kan man köpa växter och jord från er?",
      answer: [
        "Vi säljer perenner, buskar, träd och jord till trädgårdens alla projekt!",
      ],
    },
    {
      question: "Hur fungerar RUT-avdraget?",
      answer: [
        "RUT-avdrag gäller på många skötselarbeten. Vi fixar arbetet med skatteverket, på din faktura från oss är redan halva priset avdraget! Kontakta oss för mer information.",
      ],
    },
    {
      question: "Undrar ni något annat?",
      answer: ["Tveka inte med att höra av er!"],
    },
  ];
  return (
    <section className="faq section">
      <div className="faq_div">
        <div className="faq_container container grid">
          {questions.map((qna, index) => {
            return (
              <div key={index} className="faq_box">
                <h3 className="question">{qna.question}</h3>
                <div className="faq_line"></div>
                {qna.answer.map((ans, i) => {
                  return <p className="answer">{ans}</p>;
                })}
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Faqs;
