import React, { useRef, useEffect, useContext } from "react";
import { gsap } from "gsap";
import { AnimationContext } from "../../App";
import "./polymorphicHero.css";

const PolymorphicHero = ({ title, subtitle }) => {
  const { introAnim, setIntroAnim } = useContext(AnimationContext);

  const bgRef = useRef();
  const bgFilter = useRef();
  const subtitleRef = useRef();
  const titleRef = useRef();

  /*
  useEffect(() => {
    const y = window.scrollY / 3;
    bgRef.current.style.transform = `translateY(${y}px)`;
  }, []);

  window.addEventListener("scroll", parralaxScroll);

  function parralaxScroll(e) {
    const y = this.scrollY / 3;
    bgRef.current.style.transform = `translateY(${y}px)`;
  }
  */

  useEffect(() => {
    if (window.innerWidth > 768) {
      gsap.to(bgRef.current, {
        y: 600,
        scrollTrigger: {
          scrub: true,
        },
      });
    }

    if (introAnim) return;
    gsap.fromTo(
      bgFilter.current,
      {
        opacity: 0,
      },
      {
        delay: 0.5,
        duration: 1,
        opacity: 1,
      }
    );
    gsap.fromTo(
      titleRef.current,
      {
        y: 50,
        opacity: 0,
      },
      {
        y: 0,
        delay: 1,
        duration: 1,
        opacity: 1,
      }
    );
    gsap.fromTo(
      subtitleRef.current,
      {
        y: 50,
        opacity: 0,
      },
      {
        y: 0,
        delay: 1.5,
        duration: 1,
        opacity: 1,
      }
    );
    setIntroAnim(true);
  }, []);

  return (
    <section className="polymorphic_hero section">
      <div className="polymorphic_bg">
        <div ref={bgRef} className="img"></div>
        <div ref={bgFilter} className="img_overlay"></div>
      </div>

      <div className="polymorphic_hero_container containter grid">
        <h2 ref={subtitleRef} className="section_title polymorphic_title">
          {subtitle}
        </h2>
        <h1 ref={titleRef} className="section_subtitle polymorphic_subtitle">
          {title}
        </h1>
      </div>
      <div className="polymorphic_logo"></div>
    </section>
  );
};

export default PolymorphicHero;
