import React, { useEffect, useState } from "react";
import "./imageGallery.css";
import { listAll, ref, getDownloadURL } from "firebase/storage";
import { storage } from "../../firebase.js";

const ImageGallery = () => {
  const [galleryImgs, setGalleryImgs] = useState([]);
  const galleryImgsRef = ref(storage, "galleri/");

  useEffect(() => {
    listAll(galleryImgsRef).then((response) => {
      response.items.forEach((item) => {
        getDownloadURL(item).then((url) => {
          setGalleryImgs((prev) => [...prev, url]);
        });
      });
    });
  }, []);

  const sizes = ["normal", "tall", "wide", "normal"];

  return (
    <section className="gallery_section section">
      <div className="gallery_container container">
        {galleryImgs.map((url, index) => {
          return (
            <div key={index}>
              <img src={url} alt="" className="gallery_img" />
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default ImageGallery;
