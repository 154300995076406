import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import "./contactForm.css";

const ContactForm = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        process.env.REACT_APP_EMAILJS_SERVICE,
        process.env.REACT_APP_EMAILJS_TEMPLATE,
        form.current,
        process.env.REACT_APP_EMAILJS_API
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <>
      <div className="contact_div">
        <h3 className="contact_form_title">Skicka ett meddelande!</h3>
        <form className="contact_form" ref={form} onSubmit={sendEmail}>
          <div className="input_box">
            <input
              type="text"
              required
              onInvalid={(e) => e.target.setCustomValidity("Ange ditt namn")}
              onInput={(e) => e.target.setCustomValidity("")}
              name="user_name"
            />
            <label>Namn</label>
          </div>
          <div className="input_box">
            <input
              type="email"
              required
              onInvalid={(e) =>
                e.target.setCustomValidity("Ange en giltig emailadress")
              }
              onInput={(e) => e.target.setCustomValidity("")}
              name="user_email"
            />
            <label>Email</label>
          </div>
          <div className="input_box">
            <textarea
              required
              onInvalid={(e) =>
                e.target.setCustomValidity("Ange ett meddelande")
              }
              onInput={(e) => e.target.setCustomValidity("")}
              name="message"
            />
            <label>Meddelande</label>
          </div>
          <div className="input_button">
            <input type="submit" value="Skicka" />
          </div>
        </form>
      </div>
    </>
  );
};

export default ContactForm;
