import React from "react";
import "./../home.css";

const FocusPointsImgs = () => {
  return (
    <div className="info_imgs focusPoints_imgs grid">
      <div className="info_img focus_img1"></div>

      <div className="info_img focus_img2"></div>

      <div className="info_img focus_img3"></div>

      <div className="info_img focus_img4"></div>
    </div>
  );
};

export default FocusPointsImgs;
