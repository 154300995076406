import React from "react";
import "./home.css";
import ServiceImgs from "./homeServices/ServiceImgs";
import FocusPointsImgs from "./homeFocusPoints/FocusPointsImgs";
import FocusPoints from "./homeFocusPoints/FocusPoints";
import HomeServices from "./homeServices/HomeServices";
import Flower from "./flower/Flower";

const HomeSection = () => {
  return (
    <section className="infoSection section">
      <div className="container grid">
        <h1 className="section_title info_title">Ett urval av våra tjänster</h1>
      </div>
      <div className="info_container container grid">
        <ServiceImgs />
        <HomeServices />
      </div>
      <div className="container grid">
        <h1 className="section_title info_title">Varför välja oss?</h1>
      </div>

      <div className="info_container container grid">
        <FocusPointsImgs />
        <FocusPoints />
      </div>
      <div className="flower_container container grid">
        <Flower />
      </div>
    </section>
  );
};

export default HomeSection;
