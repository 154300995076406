import "./App.css";
import React, { useState, useEffect, createContext } from "react";
import Header from "./components/header/Header";
import Contact from "./components/contact/Contact";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Services from "./pages/Services";
import Gallery from "./pages/Gallery";
import About from "./pages/About";
import Footer from "./components/footer/Footer";
import FaQ from "./pages/FaQ";

export const MenuContext = createContext();
export const AnimationContext = createContext();

function App() {
  const [menuState, setMenuState] = useState(false);

  useEffect(() => {
    if (menuState) document.body.style.overflowY = "hidden";
    else document.body.style.overflowY = "unset";
  }, [menuState]);

  const [introAnim, setIntroAnim] = useState(false);

  return (
    <>
      <MenuContext.Provider value={{ menuState, setMenuState }}>
        <Header />
      </MenuContext.Provider>

      <main className="main">
        <AnimationContext.Provider
          value={{
            introAnim,
            setIntroAnim,
          }}
        >
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/om" element={<About />} />
            <Route path="/tjänster" element={<Services />} />
            <Route path="/galleri" element={<Gallery />} />
            <Route path="/faq" element={<FaQ />} />
          </Routes>
        </AnimationContext.Provider>
        <Contact />
        <Footer />
      </main>
    </>
  );
}

export default App;
