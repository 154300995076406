import React from "react";
import "./../home.css";

const ServiceImgs = () => {
  return (
    <div className="info_imgs services_imgs grid">
      <div className="info_img service_img1"></div>

      <div className="info_img service_img2"></div>

      <div className="info_img service_img3"></div>

      <div className="info_img service_img4"></div>
    </div>
  );
};

export default ServiceImgs;
