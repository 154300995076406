import React from "react";
import "./contact.css";
import ContactForm from "./ContactForm";

const Contact = () => {
  const instagramLink = "https://www.instagram.com/lovisastradgardstjanst/";
  const facebookLink = "https://www.facebook.com/Lovisastradgardstjanst/";
  return (
    <section className="contact section" id="contact">
      <div className="container">
        <h1 className="contact_title section_title">Intresserad?</h1>
        <h2 className="contact_subtitle section_subtitle">
          Välkommen att höra av dig!
        </h2>
      </div>
      <div className="contact_div">
        <div className="contact_container container grid">
          <div className="contact_content">
            <div className="contact_info">
              <div className="contact_information grid">
                <div className="contact_info">
                  <h3 className="contact_company_name">
                    Lovisas Trädgårdstjänst
                  </h3>
                  <p className="contact_email">
                    lovisastradgardstjanst@gmail.com
                  </p>
                  <p className="contact_phone">072-745 98 72</p>
                  <p className="contact_adress">
                    Lärkvägen 19, 441 40 Alingsås
                  </p>
                </div>
                <div className="contact_socials">
                  <h3 className="contact_social_title">
                    Du hittar oss även på
                  </h3>
                  <div className="contact_social_media grid">
                    <a
                      href={instagramLink}
                      target="_blank"
                      rel="noreferrer"
                      className="contact_social_link"
                    >
                      <i className="uil uil-instagram social_media"></i>
                    </a>

                    <a
                      href={facebookLink}
                      target="_blank"
                      rel="noreferrer"
                      className="contact_social_link"
                    >
                      <i className="uil uil-facebook social_media"></i>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="contact_content">
            <ContactForm />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
