import React from "react";
import "./../home.css";
import { Link } from "react-router-dom";

const HomeServices = () => {
  const services = [
    {
      title: "Trädgårdskonsultationer och rådgivning",
      description:
        "En bra start för att komma igång med ditt nya trädgårdsprojekt",
      align: "left tall",
    },
    {
      title: "Trädgårdsskötsel och beskärning",
      description:
        "Vi erbjuder skötsel till privata trädgårdar, företag och bostadsrättsföreningar",
      align: "right tall",
    },
    {
      title: "Trädgårdsanläggning",
      description:
        "Tid för förändring? Vi hjälper er förverkliga era trädgårdsvisioner!",
      align: "left span2",
    },
  ];

  return (
    <div className="info_services">
      <div className="info_services_grid grid">
        {services.map((service, index) => {
          return (
            <div key={index} className={"box " + service.align}>
              <div className="info_box_box">
                <h3 className="box_title">{service.title}</h3>
                <div className="box_line"></div>
                <p className="box_description">{service.description}</p>
              </div>

              <div className="box_line"></div>
              <div className="link_div">
                <Link to="/tjänster" className="to_services_link">
                  <span>Läs mer</span>
                  <i className="uil uil-arrow-right link_arrow"></i>
                  <div className="link_line"></div>
                </Link>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default HomeServices;
