import React from "react";
import PolymorphicHero from "../components/hero/PolymorphicHero";
import ServiceDescriptions from "../components/services/ServiceDescriptions";
import { Helmet } from "react-helmet";

const Services = () => {
  return (
    <>
      <Helmet>
        <title>Lovisas Trädgårdstjänst - Tjänster</title>
        <meta
          name="description"
          content="Lovisas trädgårdstjänst erbjuder tjänster som bland annat trädgrådskonsultation, trädgårdsskötsel, anläggning med mera. Vi håller även i olika kurser."
        />
      </Helmet>
      <PolymorphicHero
        title={"Våra tjänster"}
        subtitle={"Detta erbjuder vi!"}
      />
      <ServiceDescriptions />
    </>
  );
};

export default Services;
