import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";

const Footer = () => {
  const navLinks = [
    {
      id: 1,
      to: "/om",
      text: "Om Oss",
    },
    {
      id: 2,
      to: "/tjänster",
      text: "Tjänster",
    },
    {
      id: 3,
      to: "/faq",
      text: "Vanliga Frågor",
    },
  ];

  const instagramLink = "https://www.instagram.com/lovisastradgardstjanst/";
  const facebookLink = "https://www.facebook.com/Lovisastradgardstjanst/";

  return (
    <section className="footer">
      <div className="footer_container container grid">
        <div className="footer_title">Lovisas Trädgårdstjänst</div>
        <ul className="footer_list">
          {navLinks.map((link, index) => {
            return (
              <Link to={link.to} key={index} className="footer_link">
                {link.text}
              </Link>
            );
          })}
        </ul>
        <div className="footer_social">
          <a
            href={instagramLink}
            target="_blank"
            className="footer_social_link"
          >
            <i className="uil uil-instagram footer_social_icon"></i>
          </a>

          <a href={facebookLink} target="_blank" className="footer_social_link">
            <i className="uil uil-facebook footer_social_icon"></i>
          </a>
        </div>
        <div className="footer_logo"></div>
      </div>
    </section>
  );
};

export default Footer;
