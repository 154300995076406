import React from "react";
import { Helmet } from "react-helmet";
import Hero from "../components/hero/Hero";
import HomeSection from "../components/homeSection/HomeSection";

const Home = () => {
  return (
    <section className="home">
      <Helmet>
        <title>Lovisas Trädgårdstjänst - Trädgårdstjänst i Alingsås</title>
        <meta
          name="description"
          content="Vi på Lovisas trädgårdstjänst skapar vackra, lättskötta och hållbara trädgårdar, genom trädgårdskonsultation, anläggning, skötsel och beskärning."
        />
      </Helmet>
      <Hero />
      <HomeSection />
    </section>
  );
};

export default Home;
