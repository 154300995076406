import React from "react";
import ServiceBox from "./ServiceBox";
import "./services.css";

const ServiceDescriptions = () => {
  const services = [
    {
      service: "Trädgårdskonsultation",
      time: "2 timmar",
      description: [
        "Vi ses hos er och går under konsultationens gång runt i trädgården. Vi pratar gemensamt igenom vad ni önskar förändra och vad ni behöver hjälp med. Ni får tips och råd och har möjlighet att ställa frågor.",
        "Vi ser bland annat över rumslighet, insyn, gröna inslag samt era önskemål på funktioner i trädgården. Ni får förslag på växter, markmaterial och utformning. En konsultation tar vanligtvis 1-2 timmar, men anpassas efter era behov. ",
        "Efter konsultationen utför vi efter önskemål - planering, markarbete, levererar jord och växter samt planterar och färdigställer ert nya trädgårdsprojekt!",
      ],
      closed_description: [
        "Vi ses hos er och går under konsultationens gång runt i trädgården. Vi pratar gemensamt...",
      ],
      expandable: true,
      img: "KonsultationImg",
    },
    {
      service: "Trädgårdsskötsel",
      time: "Längden varierar",
      description: [
        "Vi erbjuder skötsel och underhåll av utemiljöer till privata trädgårdar, bostadsrättsföreningar och företag. Den kan bestå av bland annat plantering, beskärning av buskar och träd, gräsklippning, häckklippning, ogräsrensning, vattning, jordförbättring samt rensning av stenytor och gångar.",
        "För dig som privatperson gäller RUT-avdrag. Du betalar då endast 50% av kostnaden. För mer information, kontakta oss!",
      ],
      closed_description: [
        "Vi erbjuder skötsel och underhåll av utemiljöer till privata trädgårdar, bostadsrättsföreningar och...",
      ],
      expandable: true,
      img: "SkotselImg",
    },
    {
      service: "Anläggning",
      time: "Längden varierar",
      description: [
        "Är det dags att uppdatera era rabatter eller anlägga nya? Önskar ni längre blomningstid, fler torktåliga växter, mer vintergrönt eller bättre insynsskydd? Vi utför de flesta typer av planteringar!",
        "Vi planerar trädgårdsprojektet utifrån era önskemål kring färger, stil och funktion samt platsens förutsättningar. Vi är med hela vägen och tar hand om beställning och leverans av jord och växter, samt planterar och slutför.",
        "Vi samarbetar med bland annat Essunga plantskola, Hallbergs plantskola, Plantskolan i Säve samt Fagerhults garden.",
      ],
      closed_description: [
        "Är det dags att uppdatera era rabatter eller anlägga nya? Önskar ni längre blomningstid...",
      ],
      expandable: true,
      img: "AnlaggningImg",
    },
    {
      service: "Trädgårdsformgivning",
      time: "Ett helt år!",
      description: [
        "En välplanerad trädgård blir både lättskött, vacker och funktionell. Vi planerar trädgårdens utformning med planteringar, växtförslag och materialval. Vi gör planteringsplaner med arter och antal, som utformas efter era önskemål. Växtförteckning med svenska och latinska namn samt skötselråd ingår alltid.",
      ],
      closed_description: [
        "En välplanerad trädgård blir både lättskött, vacker och funktionell. Vi planerar trädgårdens...",
      ],
      expandable: true,
      img: "FormgivningImg",
    },
    {
      service: "Styling inför försäljning",
      time: "30 minuter",
      description: [
        "Höj värdet på ditt hus och din tomt inför kommande försäljning! Vi hjälper dig med rådgivning och styling liksom det praktiska arbetet.",
        "Under förberedelse till försäljning städar vi och röjer undan trädgårdsavfall, gör rabatterna fina, kantskär gräsmattan och rensar ogräs. Beskär buskar och träd för ett fräscht och luftigt intryck. Behövs det fyllas ut med nya växter så planteras dessa.",
        "Altaner och trappor fylls upp med vackra växter i krukor och inredning efter säsong. Resultatet blir en trädgård som ser välorganiserad, lättskött och inbjudande ut!",
      ],
      closed_description: [
        "Höj värdet på ditt hus och din tomt inför kommande försäljning! Vi hjälper dig med rådgivning och...",
      ],
      expandable: true,
      img: "StylingImg",
    },
    {
      service: "Beskärning",
      time: "Längden varierar",
      description: [
        "Behöver era träd ses över? Prydnadsträd samt fruktträd som äppelträd och päronträd beskär vi under vårvintern samt hösten. Under JAS-perioden (juli-september) beskär vi känsliga prydnadsträd och stenfrukter, såsom plommon och körsbärsträd.",
        "Vi klipper även häckar, buskar samt utför formklippning kontinuerligt under året!",
      ],
      closed_description: [
        "Behöver era träd ses över? Prydnadsträd samt fruktträd som äppelträd och päronträd beskär vi under...",
      ],
      expandable: true,
      img: "BeskarningImg",
    },
  ];

  const courses = [
    "Perenner och prydnadsgräs, del 1 och 2.",
    "Trädgårdsformgivning",
    "Trädgårdsskötsel under året",
    "Kransbindarkurs",
    "Bind egna buketter och binderier",
  ];

  return (
    <section className="section service_descriptions">
      <div className="services_div">
        <div className="services_container container grid">
          {services.map((service, index) => {
            return (
              <ServiceBox
                key={index}
                title={service.service}
                time={service.time}
                description={service.description}
                closed_desc={service.closed_description}
                expandable={service.expandable}
                image={service.img}
              />
            );
          })}
        </div>
        <div className="container grid">
          <div className="services_title section_title">Kurser</div>
        </div>
        <div className="courses_container container grid">
          <div className="courses_wrapper grid">
            <h3 className="courses_title">
              Vi håller i nuläget följande kurser:
            </h3>
            <div className="service_line"></div>
            {courses.map((course, index) => {
              return (
                <p key={index} className={"course"}>
                  {course}
                </p>
              );
            })}
            <div className="service_line"></div>
            <p className="courses_description">
              Kurser hålls vanligtvis i lokaler i centrala Alingsås, men kan
              efter önskemål ges på plats hos er eller ert företag. För mer
              information, kontakta oss!
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ServiceDescriptions;
