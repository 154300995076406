import React, { useState, useRef, useContext } from "react";
import { Link } from "react-router-dom";
import { MenuContext } from "../../App";
import "./header.css";

const Header = () => {
  const navLinks = [
    {
      id: 1,
      to: "/",
      text: "Hem",
    },
    {
      id: 2,
      to: "/om",
      text: "Om Oss",
    },
    {
      id: 3,
      to: "/tjänster",
      text: "Tjänster",
    },
    {
      id: 4,
      to: "/galleri",
      text: "Galleri",
    },
    {
      id: 5,
      to: "/faq",
      text: "Vanliga Frågor",
    },
  ];

  let theEnd = 0;
  const header = useRef();
  const header_line = useRef();

  window.addEventListener("scroll", function () {
    if (this.scrollY >= 10) {
      header.current.classList.add("scroll-header");
      header_line.current.classList.add("scroll-header_line");
    } else {
      header.current.classList.remove("scroll-header");
      header_line.current.classList.remove("scroll-header_line");
    }
  });

  window.addEventListener("scroll", stickyHeader);
  function stickyHeader() {
    var scrollTop =
      window.pageXOffset || this.document.documentElement.scrollTop;
    if (scrollTop > theEnd && this.window.scrollY > 0) {
      header.current.classList.add("stycky-hidden");
    } else {
      header.current.classList.remove("stycky-hidden");
    }
    theEnd = scrollTop;
  }

  window.addEventListener("focus", stickyHeader);

  /* =============== Toggle Menu =============== */
  const [Toggle, showMenu] = useState(false);

  /* =============== Burger Menu =============== */
  const [BurgerState, setBurgerState] = useState(false);

  const { menuState, setMenuState } = useContext(MenuContext);

  function menuButtonClicked() {
    showMenu(!Toggle);
    setBurgerState(!BurgerState);
    setMenuState(!menuState);
  }

  function hideMenu() {
    showMenu(false);
    setBurgerState(false);
    setMenuState(false);
  }

  function toContact() {
    hideMenu();
    const contact = document.getElementById("contact");
    console.log(contact);
    window.scrollTo({ top: contact.offsetTop, behavior: "smooth" });
  }

  return (
    <header ref={header} className="header">
      <nav className="nav container">
        <a href="/" className="escape_hatch">
          <div className="nav_logo"></div>
          <div className="nav_logo_overlay"></div>
        </a>

        <div className={Toggle ? "nav_menu show_menu" : "nav_menu"}>
          <ul className="nav_list grid">
            {navLinks.map((link, index) => {
              return (
                <Link
                  to={link.to}
                  key={index}
                  onClick={hideMenu}
                  className="nav_link"
                >
                  {link.text}
                </Link>
              );
            })}
            <button
              href="#"
              onClick={toContact}
              className="nav_link mobile_cta"
            >
              Kontakt
            </button>
          </ul>
        </div>
        <button onClick={toContact} className="nav_cta pc_cta">
          Kontakt
        </button>
        <div
          tabIndex={0}
          className={BurgerState ? "hamburger_button open" : "hamburger_button"}
          onClick={() => menuButtonClicked()}
        >
          <div className="hamburger_button_burger"></div>
        </div>
      </nav>
      <div ref={header_line} className={"header_line"}></div>
    </header>
  );
};

export default Header;
