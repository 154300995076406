import React from "react";
import PolymorphicHero from "../components/hero/PolymorphicHero";
import Faqs from "../components/faq/Faqs";
import { Helmet } from "react-helmet";

const FaQ = () => {
  return (
    <>
      <Helmet>
        <title>Lovisas Trädgårdstjänst - Frågor och svar</title>
        <meta
          name="description"
          content="Undrar du exempelvis vart vi arbetar, våra arbetstider eller hur våra tjänster fungerar? Du hittar svar på många av de vanliga frågorna här!"
        />
      </Helmet>
      <PolymorphicHero
        title={"Vanliga frågor och svar"}
        subtitle={"Undrar du något?"}
      />
      <Faqs />
    </>
  );
};

export default FaQ;
